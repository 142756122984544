import styled from "styled-components";

import { ShoppingCartDrawerStoreNameStyledProps as Props } from "./ShoppingCartDrawerStoreName.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const ShoppingCartDrawerStoreNameStyled = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    !props.geoCodeAddress ? "flex-start" : "space-between"};
  padding-bottom: 3.2rem;
  font-weight: 700;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  color: var(--palette-black-s0-l10);
  font-size: 1.4rem;

  svg {
    margin-right: 0.8rem;
  }

  @media (min-width: ${mobile}px) {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    font-size: 1.6rem;
  }

  .ShoppingCartDrawerStoreName {
    &__select-address {
      cursor: pointer;

      &--regular {
        font-weight: 400;
      }
    }

    &__name-container {
      display: flex;
      align-items: center;
    }

    &__change {
      cursor: pointer;
    }
  }
`;
export default ShoppingCartDrawerStoreNameStyled;
