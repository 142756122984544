import { useProducts } from "@sushicorp/contexts";
import { useShoppingCart } from "@sushicorp/contexts";
import { useStores } from "@sushicorp/contexts";
import { buildArtisnHeaders } from "@sushicorp/services";
import { getFullPath, getImage, scrollToElement } from "@sushicorp/utils";
import { getBenefitProductId } from "@sushicorp/utils";
import { toSentenceCaseUtility as toSentence } from "@sushicorp/utils";
import { events } from "artisn/analytics";
import { applyBenefit } from "artisn/shopping-cart";
import { Benefit, CartProduct } from "artisn/types";
import { ShareButton } from "artisn-ui-react";
import { Image } from "artisn-ui-react";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";

import Styles from "./ProductMain.styles";
import { ProductMainProps as Props } from "./ProductMain.types";
import ProductPlaceholder from "../Product/Product.placeholder";
import ProductError from "../ProductError/ProductError";
import ProductErrorMessage from "../ProductErrorMessage/ProductErrorMessage";
import Counter from "components/global/Counter/Counter";
import ProductBaseInfo from "components/global/ProductBaseInfo/ProductBaseInfo";
import ModifiersForm from "components/global/modifiers/ModifiersForm/ModifiersForm";
import AddToCartButton from "components/products/AddToCartButton/AddToCartButton";
import CONSTANTS from "config/constants";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import useOnUnmount from "hooks/useOnUnmount";
import { useProductForm } from "hooks/useProductForm";
import useShippingCost from "hooks/useShippingCost";
import { createAddToCartNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import { dismissAddToCartNotification } from "utils/notifications.utils";

import ShareSVG from "../../../../public/assets/images/share.svg";

const { WITH_SHARE_PRODUCT, WITH_CART_DRAWER } = CONSTANTS.FEATURE_FLAGS;
const { WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;
const { WITH_PURCHASE } = CONSTANTS.FEATURE_FLAGS;
const { API_URL } = CONSTANTS.API;
const { ACCOUNT_ID } = CONSTANTS.ARTISN;
const { PRODUCT_FALLBACK_IMAGE } = CONSTANTS.GENERAL;
const { logViewProductDetails } = events.product;

const ProductMain: React.FC<Props> = props => {
  const { productId, product, isLoading, isError } = props;
  const { isPageStyle = false } = props;
  const { onCloseModal, className, isModal = false } = props;
  const { isAnonymous = false, uid } = useAuth();
  const t = useI18n();
  const { selectedProduct, setSelectedProduct } = useProducts();
  const { amount: selectedProductAmount } = (product as CartProduct) ?? {};
  const router = useRouter();
  const [amount, setAmount] = useState(selectedProductAmount ?? 1);
  const { analyticsInitialized } = useAnalytics();
  const viewProductDetailsLogged = useRef(false);
  const { description, images, categories } = product ?? {};
  const { available, name } = product ?? {};
  const form = useProductForm(product);
  const [category] = categories ?? [];
  const { categoryId, name: categoryName } = category ?? {};
  const selectedProductId = product?.productId;
  const ref = useRef(selectedProductId);
  const productRef = useRef(product);
  const { selectedStore } = useStores();
  const shippingCost = useShippingCost();
  const shoppingCartContext = useShoppingCart();
  const { shoppingCart, temporalBenefit } = shoppingCartContext;
  const { setTemporalBenefit, setOpenShoppingCartDrawer } = shoppingCartContext;
  const { benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;
  const benefitProductId = getBenefitProductId(
    temporalBenefit,
    selectedBenefit
  );
  const isBenefit = benefitProductId === selectedProductId;
  const actionsStyles = isPageStyle ? "actions--page" : "actions";
  const formStyles = isPageStyle ? "ProductMain__form--page" : "";

  const { url } = getImage(images, PRODUCT_FALLBACK_IMAGE);

  const goToCategories = () => {
    router.push(`/categories/${categoryId}/${categoryName}`);
  };

  const applyBenefitHandler = async (temporalBenefit: Benefit) => {
    if (!selectedStore) return;
    if (!uid) return;

    try {
      await applyBenefit(
        {
          benefitId: temporalBenefit.benefitId,
          product,
          shippingCost,
          anonymous: isAnonymous,
          productConfig: {
            amount: 1,
            store: selectedStore,
            accountId: ACCOUNT_ID,
            customerId: uid,
            anonymous: isAnonymous
          },
          apiURL: API_URL,
          accountId: ACCOUNT_ID,
          customerId: uid
        },
        await buildArtisnHeaders()
      );
      setTemporalBenefit(undefined);
    } catch (e) {
      createErrorNotification(e.message, "Cupones");
    }
  };

  const addToCartFinishHandler = async () => {
    if (WITH_CART_DRAWER && !!selectedProduct) {
      if (onCloseModal && WITH_PRODUCT_MODAL) onCloseModal();
    }

    if (onCloseModal && WITH_PRODUCT_MODAL) onCloseModal();

    if (WITH_CART_DRAWER && !selectedProduct) {
      dismissAddToCartNotification();
      if (temporalBenefit) await applyBenefitHandler(temporalBenefit);
      createAddToCartNotification(product, () => {
        dismissAddToCartNotification();
        setOpenShoppingCartDrawer(true);
      });
      return;
    }

    if (WITH_CART_DRAWER) return;

    if (temporalBenefit) {
      await applyBenefitHandler(temporalBenefit);
      router.replace("/cart");
      return;
    }

    router.push("/cart");
  };

  const errorHandler = () => {
    if (!form) {
      return;
    }
    const errorGroup = form.renderer.find(group => group.status !== "OK");
    if (!errorGroup) {
      return;
    }
    scrollToElement(`modifier-group-${errorGroup.id}`, isModal);
  };

  const shareButtonNode = WITH_SHARE_PRODUCT ? (
    <ShareButton
      className="ProductModal__share"
      config={{
        title: `Compartir producto`,
        text: `Comparte este producto con otra persona`,
        url: `${getFullPath(product)}`
      }}
    >
      <div className="ProductMain__share-button__content">
        <div className="ProductMain__share-button__icon-container">
          <ShareSVG />
        </div>
        <p className="ProductMain__share-button__text">{t.cart.share}</p>
      </div>
    </ShareButton>
  ) : null;

  const productActionsNode = () => (
    <section className={`ProductMain__${actionsStyles}`}>
      <Counter
        className="ProductMain__counter"
        min={1}
        max={isBenefit ? 1 : undefined}
        maxDisabled={!!isBenefit}
        initialValue={selectedProductAmount ?? 1}
        onChange={value => setAmount(value)}
        disabled={!available}
      />
      <AddToCartButton
        className="ProductMain__addToCart"
        form={form}
        onError={errorHandler}
        config={{
          amount,
          comment: ""
        }}
        onFinish={addToCartFinishHandler}
        disabled={!available || !product?.attributes.showInMenu}
      />
    </section>
  );

  useEffect(() => {
    if (!product || viewProductDetailsLogged.current || !analyticsInitialized) {
      return;
    }
    logViewProductDetails({
      product
    });
    viewProductDetailsLogged.current = true;
  }, [analyticsInitialized, product]);

  useEffect(() => {
    setAmount(selectedProductAmount ?? 1);
  }, [selectedProductAmount]);

  useEffect(() => {
    if (!productRef.current || !productId) return;
    if (productId !== productRef.current?.productId) {
      setSelectedProduct(undefined);
    }
  }, [productId, setSelectedProduct]);

  useEffect(() => {
    ref.current = selectedProductId;
  }, [selectedProductId]);

  useOnUnmount(() => setSelectedProduct(undefined));

  return (
    <Styles
      className={`ProductMain ${className} ${className}--full-height`}
      isPageStyle={isPageStyle}
    >
      <div className="ProductMain__content">
        {!isLoading && product?.questions && form && productId && images ? (
          <>
            <section className="ProductMain__preview-image">
              <Image
                image={url}
                alt={`${name}`}
                errorImage="/assets/images/fallback-kobe.png"
              />
            </section>
            <section
              className={`ProductMain__form ${formStyles}`}
              id="modifiers"
            >
              <div className="ProductMain__info">
                <div className="ProductMain__baseInfo-icons">
                  <ProductBaseInfo product={product} largePrice />
                  {!isLoading && !isError && product && isModal ? (
                    <div className="ProductMain__icons">{shareButtonNode}</div>
                  ) : null}
                </div>
                {!available ? (
                  <ProductErrorMessage goToCategories={goToCategories} />
                ) : null}

                <pre className="ProductMain__description__text">
                  {toSentence(description ?? "")}
                </pre>
              </div>
              {!isModal && WITH_PURCHASE ? productActionsNode() : null}
              {WITH_PURCHASE ? (
                <ModifiersForm form={form} disabled={!available} />
              ) : null}
            </section>

            {isModal ? productActionsNode() : null}
          </>
        ) : null}
        {(isLoading && !isError) || (!productId && !isError) ? (
          <ProductPlaceholder />
        ) : null}
        {!isLoading && isError && !product ? <ProductError /> : null}
      </div>
    </Styles>
  );
};

ProductMain.defaultProps = {
  className: ""
};

export default ProductMain;
