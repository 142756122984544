import { useShoppingCart as useShoppingCartContext } from "@sushicorp/contexts";
import { useStores } from "@sushicorp/contexts";
import { useProducts } from "@sushicorp/contexts";
import { sortByProductDate } from "@sushicorp/utils";
import { goToProductDetail } from "@sushicorp/utils";
import { events } from "artisn/analytics";
import { removeBenefit } from "artisn/shopping-cart";
import { setProduct, getShoppingCartProducts } from "artisn/shopping-cart";
import { CartProduct } from "artisn/types";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { useRef } from "react";

import Styles from "./CartProducts.styles";
import { CartProductsProps as Props } from "./CartProducts.types";
import EmptyCart from "../EmptyCart/EmptyCart";
import ShoppingCartProduct from "../ShoppingCartProduct/ShoppingCartProduct";
import ShoppingCartProductPlaceholder from "../ShoppingCartProduct/ShoppingCartProduct.placeholder";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useShippingCost from "hooks/useShippingCost";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import { notify } from "utils/common.utils";
import { createErrorNotification } from "utils/notifications.utils";

const { logRemoveProductFromCart } = events.shoppingCart;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = CONSTANTS.ARTISN;
const { API_URL } = CONSTANTS.API;
const { WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;

const CartProducts: React.FC<Props> = props => {
  const { disabled, className } = props;
  const { isAnonymous = false, uid } = useAuth();
  const shoppingCartContext = useShoppingCartContext();
  const { removeProduct } = useShoppingCart();
  const { shoppingCart, showModifiers } = shoppingCartContext;
  const { setTemporalBenefit } = shoppingCartContext;
  const { benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;
  const router = useRouter();
  const products = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const empty = (products && products.length === 0) || !shoppingCart;
  const { name: shoppingCartName, id: shoppingCartId } = shoppingCart ?? {};
  const { selectedStore: store } = useStores();
  const productContext = useProducts();
  const { setSelectedProduct } = productContext;
  const { setOpenProductModal } = productContext;
  const shippingCost = useShippingCost();
  const notInitialRender = useRef(false);

  const sortedProducts = useMemo(
    () => products?.sort(sortByProductDate),
    [products]
  );

  /**
   * Function to delete product.
   *
   * @since 1.0.0
   * @param {CartProduct} product Product data.
   */
  const deleteProductHandler = async (product: CartProduct) => {
    if (!store) return;
    if (!uid) return;

    removeProduct?.({ product, store });

    notInitialRender.current = true;

    if (!shoppingCartId || !store) return;

    // If there is a benefit applied to the cart
    if (selectedBenefit) {
      const { benefitId, type } = selectedBenefit;

      /* If selected benefit is of type PRODUCT, reset context because remove
        product function already deletes benefits from cart */
      if (product.benefitId === benefitId) setTemporalBenefit(undefined);

      /* If selected benefit is of type ALTER_DELIVERY and there is just one
        product on the cart, remove the benefit and reset context */
      if (type === "ALTER_DELIVERY" && products?.length === 1) {
        try {
          const { benefitId } = selectedBenefit;

          await removeBenefit({
            benefitId,
            shippingCost,
            shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
            anonymous: isAnonymous,
            apiURL: API_URL,
            accountId: ACCOUNT_ID,
            customerId: uid
          });
          setTemporalBenefit(undefined);
        } catch (e) {
          notify(e, "Delete product");
          createErrorNotification(e.message, "Cupones");
        }
      }
    }

    logRemoveProductFromCart({ cartId: shoppingCartId, product, store });
  };

  /**
   * Execute in click event.
   *
   * @since 1.0.0
   * @param {since} productId Product id.
   */
  const onClickModal = (productId: string) => {
    setOpenProductModal(productId);
  };

  /**
   * Validate exist product page.
   *
   * @since 1.0.0
   * @returns Is correct product page.
   */
  const isPageProductDetail = () => {
    const pathname = router.pathname.split("/").slice(1);
    const isCorrectPage =
      pathname.length === 3 && pathname[2] === "[productName]";

    return isCorrectPage;
  };

  /**
   * Execute in product event click,
   *
   * @since 1.0.0
   * @param {CartProduct} product Product data.
   */
  const onClickProduct = (product: CartProduct) => {
    setSelectedProduct(product);
    if (WITH_PRODUCT_MODAL && !isPageProductDetail()) {
      onClickModal(product.productId);
    } else {
      goToProductDetail(product);
    }
  };

  /**
   * Execute in product quantity change event.
   *
   * @since 1.0.0
   * @param {number} amount Product amount.
   * @param {CartProduct} product Product data.
   */
  const onChangeQuantity = (amount: number, product: CartProduct) => {
    if (!uid) return;
    if (!store) return;

    setProduct(product, {
      amount,
      shoppingCartName,
      anonymous: isAnonymous,
      accountId: ACCOUNT_ID,
      customerId: uid,
      store
    });

    notInitialRender.current = true;
  };

  return (
    <Styles className={`CartProducts ${className}`}>
      {!products && shoppingCart
        ? Array(3)
            .fill("")
            .map((_, i) => <ShoppingCartProductPlaceholder key={i} />)
        : null}
      {empty ? (
        <EmptyCart />
      ) : (
        sortedProducts?.map((product, index) => {
          const { amount, productId, benefitId } = product;
          const isBenefitProduct =
            selectedBenefit && benefitId === selectedBenefit.benefitId;
          const modifiers = showModifiers(product);
          return (
            <ShoppingCartProduct
              product={product}
              initialQuantity={amount}
              onChangeQuantity={(amount: number) =>
                onChangeQuantity(amount, product)
              }
              onDelete={() => deleteProductHandler(product)}
              onClick={() => onClickProduct(product)}
              key={`${productId}-${index}`}
              disabled={disabled || isBenefitProduct}
              modifiers={modifiers}
            />
          );
        })
      )}
    </Styles>
  );
};

CartProducts.defaultProps = {
  className: ""
};

export default CartProducts;
