import { useGeo, useShoppingCartNotifications } from "@sushicorp/contexts";
import { useCountries } from "@sushicorp/contexts";
import { useShoppingCart } from "@sushicorp/contexts";
import { useStores } from "@sushicorp/contexts";
import { useExtraProducts } from "@sushicorp/contexts";
import { buildArtisnHeaders } from "@sushicorp/services";
import { defaultFunction, formatByCurrency } from "@sushicorp/utils";
import { validateShoppingCart } from "artisn/shopping-cart";
import { getShoppingCartTotal } from "artisn/shopping-cart";
import { useRouter } from "next/router";
import React, { useMemo } from "react";

import { getMissingProduct } from "./CartPayButton.helpers";
import Styles from "./CartPayButton.styles";
import { CartPayButtonProps as Props } from "./CartPayButton.types";
import { currencyOptionsDefault } from "components/categories/FiltersAccordion/FilterAccordion.helpers";
import Button from "components/global/Button/Button";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useCancelOrder from "hooks/useCancelOrder";
import useI18n from "hooks/useI18n";
import { shouldMock } from "utils/common.utils";
import { dismissErrorNotification } from "utils/notifications.utils";
import { dismissAddToCartNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import { checkCartBenefitsAlerts } from "utils/shoppingCart.utils";

const { ARTISN, API } = CONSTANTS;
const { API_URL } = API;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;
const { WITH_PURCHASE, WITH_DELIVERY } = CONSTANTS.FEATURE_FLAGS;
const { EXTRA_TOTAL } = CONSTANTS.STORAGE;

const CartPayButton: React.FC<Props> = props => {
  const { empty, shoppingCart, setDisabled = defaultFunction } = props;
  const { setErrors = defaultFunction, className } = props;
  const { isShoppingCartDrawer, disabled } = props;
  const { selectedCoordinates } = useGeo();
  const { uid, isAnonymous = false } = useAuth();
  const t = useI18n();
  const shoppingCartNotifications = useShoppingCartNotifications();
  const { setShowNoCoverage, setClosedStore } = shoppingCartNotifications;
  const { setShowMapCoordinates } = shoppingCartNotifications;
  const { cancelOrder } = useCancelOrder();
  const router = useRouter();
  const { selectedCountry } = useCountries();
  const { setOpenShoppingCartDrawer } = useShoppingCart();
  const { extraProducts } = useExtraProducts();
  const { selectedStore } = useStores();
  const shoppingCartTotal = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartTotal(shoppingCart);
  }, [shoppingCart]);
  const { minOrderAmount = 0, maxOrderAmount = 0 } = selectedStore ?? {};
  const { vendor } = selectedStore ?? {};
  const { maxPurchaseValue = 0 } = vendor ?? {};
  const maxValue =
    maxOrderAmount && maxOrderAmount !== 0 ? maxOrderAmount : maxPurchaseValue;
  const { currency } = selectedCountry;
  const { total = 0 } = shoppingCartTotal ?? {};

  const payButtonHandler = async () => {
    try {
      if (minOrderAmount > 0 && minOrderAmount > total) {
        dismissErrorNotification();
        setOpenShoppingCartDrawer(true);
        const minOrderError = t.errors.minOrderAmount(minOrderAmount);
        createErrorNotification(minOrderError, t.errors.minOrder);
        return;
      }

      if (maxValue > 0 && maxValue < total) {
        dismissErrorNotification();
        setOpenShoppingCartDrawer(true);
        const maxOrderError = t.errors.maxOrderAmount(maxValue);
        createErrorNotification(maxOrderError, t.errors.maxOrder);
        return;
      }
      if (!selectedCoordinates) {
        setShowMapCoordinates(true);
        return;
      }
      if (!shoppingCart || !uid) return;
      if (!Object.keys(shoppingCart.stores).length) return;

      await cancelOrder();

      const { lat, lng } = selectedCoordinates ?? {};
      const validate = !shouldMock ? validateShoppingCart : () => undefined;

      const errors = await validate(
        {
          latitude: lat,
          longitude: lng,
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
          anonymous: isAnonymous,
          apiURL: API_URL,
          accountId: ACCOUNT_ID,
          customerId: uid
        },
        await buildArtisnHeaders()
      );
      const { products, stores } = errors ?? {};

      stores?.forEach(store => {
        const { type } = store;

        switch (type) {
          case "IS_OPEN":
            if (stores.length === 1) {
              setClosedStore(true);
              throw new Error(
                "Tienda cerrada, intenta volver luego para realizar tus compras"
              );
            }
            break;

          case "OUT_OF_COVERAGE":
            if (WITH_DELIVERY) {
              setShowNoCoverage(true);
              throw new Error(
                "Estás fuera de cobertura, intenta cambiar tu ubicación para continuar"
              );
            }
            break;

          default:
            throw new Error("Ocurrió un error al validar el carrito");
            break;
        }
      });

      const validationErrors = stores?.filter(store => {
        if (!WITH_DELIVERY) {
          return !(store.type === "OUT_OF_COVERAGE");
        }
        return store;
      });

      if (validationErrors?.length) return;

      const hasErrors = !!products?.length;

      if (hasErrors && !shouldMock) {
        setDisabled(true);
        if (!isShoppingCartDrawer) {
          dismissAddToCartNotification();
          setOpenShoppingCartDrawer(true);
          return;
        }
        dismissErrorNotification();
        const productsMessage =
          getMissingProduct(products) || t.errors.productError;
        createErrorNotification(productsMessage);
        setErrors(errors);
        return;
      }

      if (products?.length) {
        throw new Error(
          "Error al validar los productos del carrito de compras"
        );
      }

      await checkCartBenefitsAlerts(isAnonymous, uid);

      setOpenShoppingCartDrawer(false);
      dismissAddToCartNotification();
      localStorage.setItem(EXTRA_TOTAL, JSON.stringify(extraProducts) ?? "");
      router.push("/checkout?redirect=false");
    } catch (error) {
      dismissErrorNotification();
      createErrorNotification(error.message);
    }
  };

  return (
    <Styles className={`CartPayButton ${className}`}>
      <Button
        onClick={payButtonHandler}
        disabled={empty || !WITH_PURCHASE || disabled}
        className="Cart__summary__button"
        color="primary"
      >
        <span>{t.cart.goCheckout}</span>
        <span>
          {total
            ? `${formatByCurrency(total, currencyOptionsDefault(currency, 2))}`
            : "$0.00"}
        </span>
      </Button>
    </Styles>
  );
};

CartPayButton.defaultProps = {
  className: ""
};

export default CartPayButton;
