import { useGeo, useGlobal, useStores } from "@sushicorp/contexts";
import { useFetchGoogleAddressFromCoords } from "@sushicorp/services";
import React, { useEffect, useState } from "react";

import Styles from "./ShoppingCartDrawerStoreName.styles";
import { ShoppingCartDrawerStoreNameProps as Props } from "./ShoppingCartDrawerStoreName.types";
import ShippingAddressModal from "components/checkout/ShippingAddressModal/ShippingAddressModal";
import CONSTANTS from "config/constants";
import { useTalkShop } from "contexts/talkShop/talkShop.context.hooks";
import useI18n from "hooks/useI18n";
import { createErrorNotification } from "utils/notifications.utils";

import LocationSVG from "../../../../../public/assets/images/location.svg";

const { FEATURE_FLAGS } = CONSTANTS;
const { WITH_TALK_SHOP, WITH_CHANGE_BUTTON } = FEATURE_FLAGS;

const ShoppingCartDrawerStoreName: React.FC<Props> = props => {
  const { setPayButtonDisabled } = props;
  const t = useI18n();
  const { selectedCoordinates } = useGeo();
  const { setDrawerConfig } = useGlobal();
  const { selectedStore } = useStores();
  const { talkShopProvider, talkShopIdentifier } = useTalkShop();
  const { data: predictions, error } = useFetchGoogleAddressFromCoords(
    process.env.NEXT_PUBLIC_MAPS_API_KEY ?? "",
    selectedCoordinates
  );
  const [geoCodeAddress, setGeoCodeAddress] = useState<string | undefined>();
  const [openChooseAddress, setOpenChooseAddress] = useState<boolean>(false);
  const { message } = error ?? {};
  const talkShop = !!talkShopProvider && !!talkShopIdentifier && WITH_TALK_SHOP;

  useEffect(() => {
    if (!predictions || !predictions.length) return;
    const { formatted_address } = predictions[0];
    setGeoCodeAddress(formatted_address);
    setDrawerConfig(p => ({ ...p, location: formatted_address }));
  }, [predictions, setDrawerConfig]);

  useEffect(() => {
    if (message) createErrorNotification(message);
  }, [message]);

  useEffect(() => {
    setPayButtonDisabled(false);

    if (!talkShop || !!geoCodeAddress) return;
    setPayButtonDisabled(true);
  }, [geoCodeAddress, setPayButtonDisabled, talkShop]);

  return (
    <Styles
      className="ShoppingCartDrawerStoreName"
      geoCodeAddress={!!geoCodeAddress}
    >
      {!geoCodeAddress && talkShop ? (
        <>
          <LocationSVG />
          <p
            className="ShoppingCartDrawerStoreName__select-address"
            onClick={() => setOpenChooseAddress(true)}
          >
            {`${t.common.deliveryIn} `}
            <span className="ShoppingCartDrawerStoreName__select-address--regular">
              {t.common.selectAddress}
            </span>
          </p>
        </>
      ) : (
        <>
          <div className="ShoppingCartDrawerStoreName__name-container">
            <LocationSVG />
            {selectedStore?.storeName}
          </div>
          {talkShop && WITH_CHANGE_BUTTON && (
            <span
              className="ShoppingCartDrawerStoreName__change"
              onClick={() => setOpenChooseAddress(true)}
            >
              Cambiar
            </span>
          )}
        </>
      )}
      <ShippingAddressModal
        opened={openChooseAddress}
        onClose={() => setOpenChooseAddress(false)}
        locationOnly
      />
    </Styles>
  );
};

ShoppingCartDrawerStoreName.defaultProps = {};

export default ShoppingCartDrawerStoreName;
